
const ShipmentGroupStatus = {
    New:4
}
const ShipmentStatus = {
    New:3
}
const PaymentStatus = {
    Pending:1,
    Paid:2
}
const InvoiceType = {
    Shipment:7,
    Order:8,
    General:11,
}
const PaymentType = {
    Aba:9,
    Cash:10
}
const CurrencyType = {
    UsDollar:12,
    VietDong:13,
}
const OrderType = {
    Regular:14,
    PlaceHolder:15,
    Stale:18,
    Preorder:19,
}
const Warehouse = {
    UnitedStates:16,
    Vietnam:17,
}
export  {
    ShipmentGroupStatus,
    ShipmentStatus,
    PaymentStatus,
    PaymentType,
    InvoiceType,
    CurrencyType,
    OrderType,
    Warehouse
}