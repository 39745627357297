var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"grey lighten-3",attrs:{"elevation":"0","width":"20%"}},[_c('v-card-text',[_c('h1',[_vm._v("Filter")])]),_c('v-card-text',[_c('v-container',{staticClass:"white rounded-xl"},[_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","label":"Title"},model:{value:(_vm.filter.searchText),callback:function ($$v) {_vm.$set(_vm.filter, "searchText", $$v)},expression:"filter.searchText"}})],1),_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Min Price","prefix":"$"},on:{"change":function($event){_vm.filter.minPrice =
                            _vm.filter.minPrice != '' ? _vm.filter.minPrice : null}},model:{value:(_vm.filter.minPrice),callback:function ($$v) {_vm.$set(_vm.filter, "minPrice", _vm._n($$v))},expression:"filter.minPrice"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Max Price","prefix":"$"},on:{"change":function($event){_vm.filter.maxPrice =
                            _vm.filter.maxPrice != '' ? _vm.filter.maxPrice : null}},model:{value:(_vm.filter.maxPrice),callback:function ($$v) {_vm.$set(_vm.filter, "maxPrice", _vm._n($$v))},expression:"filter.maxPrice"}})],1),_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Min US Quantity"},on:{"change":function($event){_vm.filter.minUsQuantity =
                            _vm.filter.minUsQuantity != ''
                                ? _vm.filter.minUsQuantity
                                : null}},model:{value:(_vm.filter.minUsQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "minUsQuantity", _vm._n($$v))},expression:"filter.minUsQuantity"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Max US Quantity"},on:{"change":function($event){_vm.filter.maxUsQuantity =
                            _vm.filter.maxUsQuantity != ''
                                ? _vm.filter.maxUsQuantity
                                : null}},model:{value:(_vm.filter.maxUsQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "maxUsQuantity", _vm._n($$v))},expression:"filter.maxUsQuantity"}})],1),_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Min VN Quantity"},on:{"change":function($event){_vm.filter.minVnQuantity =
                            _vm.filter.minVnQuantity != ''
                                ? _vm.filter.minVnQuantity
                                : null}},model:{value:(_vm.filter.minVnQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "minVnQuantity", _vm._n($$v))},expression:"filter.minVnQuantity"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Max VN Quantity"},on:{"change":function($event){_vm.filter.maxVnQuantity =
                            _vm.filter.maxVnQuantity != ''
                                ? _vm.filter.maxVnQuantity
                                : null}},model:{value:(_vm.filter.maxVnQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "maxVnQuantity", _vm._n($$v))},expression:"filter.maxVnQuantity"}})],1),_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Min Quantity"},on:{"change":function($event){_vm.filter.minQuantity =
                            _vm.filter.minVnQuantity != ''
                                ? _vm.filter.minQuantity
                                : null}},model:{value:(_vm.filter.minQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "minQuantity", _vm._n($$v))},expression:"filter.minQuantity"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","dense":"","label":"Max Quantity"},on:{"change":function($event){_vm.filter.maxQuantity =
                            _vm.filter.maxVnQuantity != ''
                                ? _vm.filter.maxQuantity
                                : null}},model:{value:(_vm.filter.maxQuantity),callback:function ($$v) {_vm.$set(_vm.filter, "maxQuantity", _vm._n($$v))},expression:"filter.maxQuantity"}})],1),_c('v-container',[_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","label":"Brand","items":_vm.brands,"item-text":"displayName","item-value":"id"},model:{value:(_vm.filter.brandId),callback:function ($$v) {_vm.$set(_vm.filter, "brandId", $$v)},expression:"filter.brandId"}})],1),_c('v-container',[_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","label":"Category","items":_vm.categories,"item-text":"displayName","item-value":"id"},model:{value:(_vm.filter.lvl1Id),callback:function ($$v) {_vm.$set(_vm.filter, "lvl1Id", $$v)},expression:"filter.lvl1Id"}})],1),_c('v-container',[_c('v-autocomplete',{attrs:{"rounded":"","outlined":"","dense":"","label":"Type","items":_vm.productTypes,"item-text":"displayName","item-value":"id"},model:{value:(_vm.filter.lvl2Id),callback:function ($$v) {_vm.$set(_vm.filter, "lvl2Id", $$v)},expression:"filter.lvl2Id"}})],1),_c('v-container',{staticClass:"d-flex justify-space-between",attrs:{"fluid":""}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","icon":"","loading":_vm.isExportLoading},on:{"click":_vm.generateProductReport}},[_c('i',{staticClass:"fa-solid fa-download"})]),_c('v-btn',{attrs:{"color":"primary","depressed":"","rounded":"","loading":_vm.isLoading},on:{"click":_vm.emitEvent}},[_c('i',{staticClass:"fas fa-sync-alt mr-2"}),_vm._v(" Apply ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }