<template>
	<v-dialog v-model="input" max-width="600">
		<v-card class="rounded-xl">
			<v-card-text class="pb-0">
				<v-container>
					<v-container class="d-flex flex-column">
						<v-container
							style="border: lightgray 1px solid"
							class="rounded-xl"
							v-show="!successful">
							<div class="d-flex justify-space-between mb-2">
								<h1>Total Amount</h1>
								<v-spacer></v-spacer>
								<h1>
									<span class="red--text"
										>$
										{{
											convertToDecimal(totalAmount)
										}}</span
									>
								</h1>
							</div>
							<div class="d-flex justify-space-between mb-2">
								<h1>Total Bill Received</h1>
								<h1>
									<span class="green--text"
										>$
										{{
											convertToDecimal(totalBillReceived)
										}}</span
									>
								</h1>
							</div>
							<div class="d-flex justify-space-between mb-2">
								<h1>Bill to Receive</h1>
								<h1>
									<span class="green--text"
										>$ {{ billReceived.toFixed(2) }}</span
									>
								</h1>
							</div>
						</v-container>

						<v-container
							style="border: lightgray 1px solid"
							class="rounded-xl"
							v-show="successful">
							<div class="d-flex justify-space-between mb-2">
								<h1>Change:</h1>
								<h1>
									<span class="blue--text"
										>$ {{ convertToDecimal(change) }}</span
									>
								</h1>
							</div>
						</v-container>
					</v-container>
					<v-container class="d-flex justify-center">
						<v-card
							elevation="0"
							class="rounded-xl"
							outlined
							width="400">
							<v-card-text
								><div class="d-flex justify-space-between mb-5">
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(1)"
										>1</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(2)"
										>2</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(3)"
										>3</v-btn
									>
								</div>
								<div class="d-flex justify-space-between mb-5">
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(4)"
										>4</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(5)"
										>5</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(6)"
										>6</v-btn
									>
								</div>
								<div class="d-flex justify-space-between mb-5">
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(7)"
										>7</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(8)"
										>8</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(9)"
										>9</v-btn
									>
								</div>
								<div class="d-flex justify-space-between">
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(-2)"
										><i class="fas fa-backspace"></i
									></v-btn>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(0)"
										>0</v-btn
									>
									<v-btn
										class="numpad-btn"
										color="warning"
										depressed
										rounded
										x-large
										@click="triggerBtn(-1)"
										>00</v-btn
									>
								</div></v-card-text
							>
						</v-card></v-container
					>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn
					v-show="!successful"
					color="primary"
					depressed
					rounded
					@click="addCash">
					<i class="fas fa-check mr-2"></i>Confirm
				</v-btn>
				<v-btn
					v-show="successful"
					color="primary"
					depressed
					rounded
					:disabled="!allowOk"
					@click="emitEvent">
					<i class="fas fa-check mr-2"></i>Ok
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import { PaymentType } from "@/js/enum";
//import backendService from "@/services/backend-service.js";
export default {
	name: "CashDialog",
	mixins: [generalMixin],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		pTotalAmount: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			allowOk: true,
			billReceived: 0,
			totalBillReceived: 0,
			change: 0,
			successful: false,
			options: {
				locale: "en-US",
				prefix: "$",
				precision: 2,
			},
		};
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		totalAmount: {
			get() {
				return this.pTotalAmount;
			},
			set(val) {
				this.$emit("total-amount", val / 100);
			},
		},
	},
	created: function () {},

	methods: {
		addCash() {
			this.totalBillReceived += this.billReceived * 100;
			this.totalAmount = this.totalAmount - this.billReceived * 100;
			this.billReceived = 0;
			this.timer(100).then(() => {
				if (this.totalAmount <= 0) {
					this.change = this.totalAmount * -1;
					this.successful = true;
				}
			});
		},
		emitEvent() {
			let paymentInfo = {
				typeId: this.getIdByEnum(PaymentType.Cash),
				tender: this.totalBillReceived,
				cashChange: this.change,
			};
			this.allowOk = false;
			this.input = false;
			this.$emit("handle-event", paymentInfo);
		},
		triggerBtn(val) {
			let newVal;
			if (val == -2) {
				newVal = parseInt(this.billReceived * 10);
				this.billReceived = parseFloat(newVal / 100);
			} else if (val == -1) {
				newVal = this.billReceived * 10000;
				this.billReceived = parseFloat(newVal / 100);
			} else {
				newVal = this.billReceived * 1000 + val;
				this.billReceived = parseFloat(newVal / 100);
			}
		},
	},
	destroyed: function () {},
	watch: {
		input(val) {
			if (val == false) {
				this.billReceived = 0;
				this.totalBillReceived = 0;
				this.totalAmount = 0;
				this.allowOk = true;
				this.successful = false;
			}
		},
	},
};
</script>
<style scoped>
.numpad-btn:hover {
	cursor: pointer;
	opacity: 0.5;
	background-color: blue !important;
}
</style>
