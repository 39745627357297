<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="filteredCustomers"
            :items-per-page="15"
            class="elevation-1">
            <template v-slot:header>
                <tr>
                    <th v-for="(header, idx) in headers" :key="`header-${idx}`">
                        <v-text-field
                            dense
                            class="ma-2"
                            rounded
                            v-model="search[header.value]"
                            placeholder="filter"
                            solo-inverted
                            hide-details
                            flat></v-text-field>
                    </th>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <a
                            @click="
                                $router
                                    .push({
                                        name: 'CustomerUpdate',
                                        params: { id: item.id },
                                    })
                                    .catch(() => {})
                            "
                            >{{ item.id }}</a
                        >
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.address1 }}</td>
                    <td>{{ item.address2 }}</td>
                    <td>{{ item.phone }}</td>
                </tr>
            </template></v-data-table
        >
    </v-container>
</template>
<script>
import backendService from "@/services/backend-service";

export default {
    name: "CustomerList",
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    align: "start",
                    value: "id",
                },
                { text: "Name", value: "name" },
                { text: "Address 1", value: "address1" },
                { text: "Address 2", value: "address2" },
                { text: "Phone", value: "phone" },
            ],
            customer: {},
            search: {},
        };
    },
    computed: {
        filteredCustomers() {
            let customers = this.customers;
            for (let key in this.search) {
                let searchStr = this.search[key].trim().toLowerCase();
                let header = this.headers.find((header) => header.value == key);
                if (searchStr && searchStr.length) {
                    customers = customers.filter((e) => {
                        let str = this.getDataText(header, e);
                        return str
                            .toString()
                            .trim()
                            .toLowerCase()
                            .includes(this.search[key].toLowerCase());
                    });
                }
            }
            return customers;
        },
        customers: {
            get() {
                return this.$store.getters["customers"];
            },
            set(val) {
                this.$store.commit("customers", val);
            },
        },
    },
    methods: {
        addCustomer() {
            backendService.addCustomer(this.customer).then((response) => {
                this.customer.id = response.data;
                this.customers.push(this.customer);
                this.customer = {};
            });
        },
        getDataText(header, customer) {
            if (header.value == "id") {
                return customer.id.toString();
            } else if (header.value == "name") {
                return customer.name;
            } else if (header.value == "address1") {
                return customer.address1;
            } else if (
                header.value == "address2" &&
                customer.address2 != null
            ) {
                return customer.address2;
            } else if (header.value == "phone") {
                return customer.phone;
            } else {
                return "";
            }
        },
    },
};
</script>
