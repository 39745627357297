<template>
  <v-container
    fluid
    class="pa-0"
    :class="{
      'no-background': $route.name == 'HomePage' && !isMobile,
      'primary darken-1':
        $route.name != 'HomePage' && $route.name != 'PublicProducts',
      'orange darken-2': $route.name == 'HomePage' && isMobile,
      'orange darken-2': $route.name == 'PublicProducts',
    }"
  >
    <v-app-bar
      dark
      style="background-color: rgba(255, 255, 255, 0)"
      elevation="0"
      v-if="$route.name != 'Shipment.PrinterFriendly'"
    >
      <v-app-bar-nav-icon
        v-if="$route.name != 'HomePage'"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        @click="$router.push({ name: 'HomePage' }).catch(() => {})"
      >
        <v-container class="d-flex align-center" v-if="!isMobile"
          ><img
            class="mx-10"
            src="@/assets/mtl-cargo-logo-2.png"
            style="width: 90px"
          />

          <span
            style="font-family: 'Sora', sans-serif"
            class="font-weight-black"
          >
            MTL Cargo - Chuyen Hang Ve VN
          </span></v-container
        >
        <v-container v-else class="d-flex align-center">
          <img
            class="mx-5"
            src="@/assets/mtl-cargo-logo-2.png"
            style="width: 70px"
          />

          <span
            v-if="isLoggedIn"
            style="font-family: 'Sora', sans-serif; font-size: 13px !important"
          >
            MTL Cargo - Chuyen Hang Ve VN
          </span>
        </v-container>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>
      <v-btn
        v-if="routeName != 'HomePage' && routeName != 'PublicProducts'"
        class="mx-2"
        :class="{
          primary: routeName != 'Shipment.Cart',
          warning: routeName == 'Shipment.Cart',
          error: isUpdatingCart == true,
        }"
        depressed
        rounded
        @click="$router.push({ name: 'Shipment.Cart' }).catch(() => {})"
        ><i class="fas fa-shopping-cart mr-2"></i> Cart ({{ cartCount }})
      </v-btn>
      <v-btn
        v-if="!isLoggedIn"
        text
        dark
        elevation="0"
        @click="$router.push({ name: 'UserRegister' }).catch(() => {})"
      >
        <i class="fas fa-user-plus mr-2"></i>
        Sign Up
      </v-btn>
      <v-btn
        v-if="!isLoggedIn"
        text
        dark
        elevation="0"
        @click="$router.push({ name: 'Login' }).catch(() => {})"
      >
        <i class="fas fa-sign-in-alt mr-2"></i>
        Login
      </v-btn>

      <v-menu left bottom offset-y min-width="150px" v-if="isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="blue--text text--darken-2"
            v-if="isLoggedIn && isAdmin"
            @click="$router.push({ name: 'ShipmentManagement' })"
          >
            <v-list-item-title>
              <i class="fas fa-dolly mr-5"></i>
              Shipment Manager
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="blue--text text--darken-2"
            v-if="isLoggedIn"
            @click="logout"
          >
            <v-list-item-title>
              <i class="fas fa-sign-out-alt mr-5"></i>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'NewShipment' }).catch(() => {})"
            >
              <i class="fas fa-plus mr-2"></i> New Shipment</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'ShipmentList' }).catch(() => {})"
            >
              <i class="fas fa-cubes mr-2"></i> Shipment List</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'NewInvoice' }).catch(() => {})"
              ><i class="fas fa-plus mr-2"></i> New Invoice</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'InvoiceList' }).catch(() => {})"
            >
              <i class="fas fa-file-invoice mr-2"></i> Invoice
              List</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              @click="
                $router.push({ name: 'NewShipmentGroup' }).catch(() => {})
              "
            >
              <i class="fas fa-plus mr-2"></i>New Shipment
              Group</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="
                $router.push({ name: 'ShipmentGroupList' }).catch(() => {})
              "
              ><i class="fas fa-plane-departure mr-2"></i> Shipment Group
              List</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'NewCustomer' }).catch(() => {})"
            >
              <i class="fas fa-user-plus mr-2"></i>New
              Customer</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'CustomerList' }).catch(() => {})"
              ><i class="fas fa-users mr-2"></i> Customer
              List</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'NewProduct' }).catch(() => {})"
              ><i class="fas fa-plus mr-2"></i> New Product</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="
                $router
                  .push({
                    name: 'Shipment.StorePage',
                  })
                  .catch(() => {})
              "
              ><i class="fas fa-store mr-2"></i>Products</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="$router.push({ name: 'Shipment.Cart' }).catch(() => {})"
              ><i class="fas fa-shopping-cart mr-2"></i> Cart ({{
                cartCount
              }})</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="
                $router.push({ name: 'Shipment.OrderList' }).catch(() => {})
              "
              ><i class="fas fa-th-large mr-2"></i> Order
              List</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              @click="
                $router.push({ name: 'Shipment.ContactUs' }).catch(() => {})
              "
              ><i class="fa-regular fa-address-book mr-2"></i>Contact
              Us</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
//import bus from "@/bus/bus.js";
import generalMixin from "@/js/general-mixin.js";
import backendService from "@/services/backend-service";
//import backendService from "../services/backend-service";
export default {
  name: "MainNavbar",

  mixins: [generalMixin],
  computed: {
    isMobile() {
      return this.$store.getters["isMobile"];
    },
    cartCount() {
      return this.$store.getters["cartCount"];
    },
    categories() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["category"]
      )
        return this.$store.getters["dropdownToOptions"]["category"].options;
      return [];
    },
    routeName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  created: function () {},
  methods: {
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      this.showContainer = false;
    },
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
    },
    showDropdown(dropdown) {
      this.isUncollapsed[dropdown] = !this.isUncollapsed[dropdown];
    },
    logout() {
      backendService;
      this.$router.push({ name: "Logout" }).catch(() => {});
    },
    isUpdatingCart() {
      return this.$store.getters["isUpdatingCart"];
    },
  },
};
</script>
<style scoped>
nav .btn {
  color: white;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #3473d9;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 32px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.sidenav a:hover {
  color: #aaaaaa;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.dropdown-container {
  background-color: #516894;
  padding-left: 8px;
}
#categories-dropdown a:first-child {
  font-weight: bold;
}
.brand-title {
  cursor: pointer;
}
</style>
