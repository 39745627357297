<template>
  <v-container fluid class="d-flex justify-space-between pa-0">
    <v-card elevation="0" class="grey lighten-3 rounded-xl" width="25%">
      <v-card-text class="d-flex align-center justify-space-between">
        <h1 v-if="!isUpdating">New Shipment Group</h1>
        <h1 v-else>Shipment Group {{ shipmentGroup.id }}</h1>
        <v-btn
          v-if="isUpdating"
          color="primary"
          depressed
          rounded
          @click="updateShipmentGroup"
          :loading="isLoading"
        >
          <i class="fas fa-save mr-2"></i>Update Shipment Group
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          rounded
          @click="addShipmentGroup"
          :loading="isLoading"
        >
          <i class="fas fa-plane-departure mr-2"></i>Add Shipment Group
        </v-btn>
      </v-card-text>
      <v-card-text>
        <v-container class="d-flex flex-column white rounded-xl">
          <v-autocomplete
            rounded
            outlined
            dense
            v-model="shipmentGroup.statusId"
            label="Status"
            :items="statuses"
            item-text="displayName"
            item-value="id"
          ></v-autocomplete>
          <v-autocomplete
            rounded
            outlined
            dense
            v-model="shipmentGroup.typeId"
            label="Type"
            :items="types"
            item-text="displayName"
            item-value="id"
          ></v-autocomplete
          ><v-date-picker v-model="shipmentGroup.shipmentDate"></v-date-picker>
        </v-container>
      </v-card-text>
      <v-card-text class="ma-0">
        <v-container class="white rounded-xl">
          <v-autocomplete
            rounded
            outlined
            dense
            label="Shipment Status"
            :items="shipmentStatuses"
            item-text="displayName"
            item-value="id"
            v-model="shipmentStatusId"
          ></v-autocomplete>

          <v-btn
            class="mb-2"
            width="100%"
            color="error"
            depressed
            rounded
            @click="applyAllDialog = true"
            :loading="isApplyAllLoading"
            ><i class="fas fa-globe-americas mr-2"></i>Apply to All
            Shipments</v-btn
          >

          <v-btn
            class="mb-2"
            width="100%"
            color="primary"
            depressed
            rounded
            @click="generateReport"
            :loading="isGenerateLoading"
            ><i class="fa-solid fa-download"></i>Generate Excel Report</v-btn
          >
        </v-container></v-card-text
      >
    </v-card>
    <v-card class="grey lighten-3" elevation="0" width="75%" v-if="isUpdating">
      <v-card-text>
        <v-container class="white rounded-xl mb-2">
          <v-data-table
            elevation="0"
            :headers="headers"
            :items="filteredShipments"
            :items-per-page="15"
            class="elevation-1"
          >
            <template v-slot:header>
              <tr>
                <th v-for="(header, idx) in headers" :key="`header-${idx}`">
                  <v-text-field
                    dense
                    class="ma-2"
                    rounded
                    v-model="search[header.value]"
                    placeholder="filter"
                    solo-inverted
                    hide-details
                    flat
                  ></v-text-field>
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr
                :class="{
                  'payment-paid': checkIfPaid(item.paymentStatusId),
                }"
              >
                <td>
                  <a
                    @click="
                      $router.push({
                        name: 'ShipmentUpdate',
                        params: { id: item.id },
                      })
                    "
                    >{{ item.id }}</a
                  >
                </td>
                <td>{{ item.mtlTracking }}</td>
                <td>{{ getCustomerName(item.senderId) }}</td>
                <td>{{ getCustomerName(item.receiverId) }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.totalBox }}/{{ item.totalWeight }}</td>
                <td>{{ convertToDate(item.shipmentDate) }}</td>
                <td>
                  {{ getOptionName(item.shipmentStatusId) }}
                </td>
                <td>
                  <a
                    v-if="item.invoiceId"
                    @click="
                      $router.push({
                        name: 'InvoiceUpdate',
                        params: { id: item.id },
                      })
                    "
                    >{{ item.invoiceId }}</a
                  >
                </td>
              </tr>
            </template></v-data-table
          >
        </v-container>
        <v-container class="white rounded-xl">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab>Regular ({{ regularOrders.length }})</v-tab>
            <v-tab>Preorder ({{ preorders.length }})</v-tab>
            <v-tab>On Hold ({{ otherOrders.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl mr-2 flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in regularOrders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="regularOrders[idx]"
                  @handle-event="refilterOrders"
                ></order-options>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in preorders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="preorders[idx]"
                  @handle-event="refilterOrders"
                ></order-options>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl mr-2 flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in otherOrders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="otherOrders[idx]"
                  @handle-event="refilterOrders"
                ></order-options> </v-container
            ></v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card-text>
    </v-card>
    <general-dialog
      :dialog="applyAllDialog"
      :options="applyAllOptions"
      v-model="applyAllDialog"
      @handle-event="updateShipmentStatusByGroupId"
    ></general-dialog>
  </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
import GeneralDialog from "@/components/GeneralDialog.vue";
import { OrderType, ShipmentGroupStatus } from "@/js/enum";
import OrderCard from "@/components/OrderCard.vue";
import OrderOptions from "@/components/OrderOptions.vue";
export default {
  name: "ShipmentGroup",
  mixins: [generalMixin],
  components: {
    "order-card": OrderCard,
    "order-options": OrderOptions,
    "general-dialog": GeneralDialog,
  },
  data() {
    return {
      isGenerateLoading: false,
      isGenerateOrderLoading: false,
      isUpdating: false,
      isLoading: false,
      shipmentStatusId: null,
      shipmentGroup: { shipmentDate: null, statusId: null },
      isApplyAllLoading: false,
      applyAllDialog: false,
      applyAllOptions: {
        btnText: "Yes",
        message: "This action is not revertable!",
        title:
          "Are you sure you want to apply the same status to all shipment under this group?",
      },
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Tracking #",
          align: "start",
          value: "mtlTracking",
        },

        { text: "Sender", value: "senderId" },
        { text: "Receiver", value: "receiverId" },
        { text: "Description", value: "description" },
        { text: "Box/Weight", value: "boxWeight" },
        { text: "Shipment Date", value: "shipmentDate" },
        {
          text: "Shipment Status",
          value: "shipmentStatusId",
        },
        {
          text: "Invoice Id",
          value: "invoiceId",
        },
      ],
      orders: [],
      search: {},
      tab: null,
    };
  },
  mounted: function () {
    this.shipmentGroup.statusId = this.getIdByEnum(ShipmentGroupStatus.New);
    if (this.$route.params.id) {
      this.getUpdateForm();
    }
  },
  computed: {
    Regular() {
      return this.getIdByEnum(OrderType.Regular);
    },
    PlaceHolder() {
      return this.getIdByEnum(OrderType.PlaceHolder);
    },
    Stale() {
      return this.getIdByEnum(OrderType.Stale);
    },
    Preorder() {
      return this.getIdByEnum(OrderType.Preorder);
    },
    idToOption() {
      return this.$store.getters["idToOption"];
    },
    statuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentGroupStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentGroupStatus"]
          .options;
      return [];
    },
    types() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentGroupType"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentGroupType"]
          .options;
      return [];
    },
    shipmentStatuses() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["shipmentStatus"]
      )
        return this.$store.getters["dropdownToOptions"]["shipmentStatus"]
          .options;
      return [];
    },
    customers() {
      return this.$store.getters["customers"];
    },
    filteredShipments() {
      let shipments = this.shipments;
      for (let key in this.search) {
        let searchStr = this.search[key].trim().toLowerCase();
        let header = this.headers.find((header) => header.value == key);
        if (searchStr && searchStr.length) {
          shipments = shipments.filter((e) => {
            let str = this.getDataText(header, e);
            return str
              .toString()
              .trim()
              .toLowerCase()
              .includes(this.search[key].toLowerCase());
          });
        }
      }
      return shipments;
    },
    shipments() {
      return this.$store.getters["shipments"].filter(
        (x) => x.groupId == this.shipmentGroup.id
      );
    },
    regularOrders() {
      return this.orders.filter((e) => e.typeId == this.Regular);
    },
    preorders() {
      return this.orders.filter((e) => e.typeId == this.Preorder);
    },
    otherOrders() {
      return this.orders.filter(
        (e) => e.typeId != this.Regular && e.typeId != this.Preorder
      );
    },
  },
  methods: {
    addShipmentGroup() {
      this.isLoading = true;
      backendService.addShipmentGroup(this.shipmentGroup).then((response) => {
        this.shipmentGroup.id = response.data;
        this.shipmentGroup.totalWeight = 0;
        this.shipmentGroup.totalBox = 0;
        this.shipmentGroup.totalValue = 0;
        this.$store.commit("addShipmentGroup", this.shipmentGroup);
        this.timer(1000).then(() => {
          this.isLoading = false;
          this.isUpdating = true;
          this.$router.push({
            name: "ShipmentGroupUpdate",
            params: { id: response.data },
          });
          eventBus.$emit(
            "setSnackbar",
            "Successfully added a new shipment group",
            "success",
            true
          );
        });
      });
    },
    generateReport() {
      this.isGenerateLoading = true;

      backendService
        .generateReportByGroupId(this.shipmentGroup.id)
        .then((response) => {
          var binary = atob(response.data);

          var array = new Uint8Array(binary.length);
          for (var i = 0; i < binary.length; i++) {
            array[i] = binary.charCodeAt(i);
          }

          const url = window.URL.createObjectURL(new Blob([array]));

          const a = document.createElement("a");
          a.href = url;
          a.download = "Report.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();

          this.timer(1000).then(() => {
            this.isGenerateLoading = false;
          });
        })
        .catch(() => {
          this.timer(1000).then(() => {
            this.isGenerateLoading = false;
            this.errorSnackbar("Ops! Something is not right");
          });
        });
    },
    generateOrderReport() {
      this.isGenerateOrderLoading = true;
      backendService
        .generateOrderReportByGroupId(this.shipmentGroup.id)
        .then((response) => {
          var binary = atob(response.data);

          var array = new Uint8Array(binary.length);
          for (var i = 0; i < binary.length; i++) {
            array[i] = binary.charCodeAt(i);
          }

          const url = window.URL.createObjectURL(new Blob([array]));

          const a = document.createElement("a");
          a.href = url;
          a.download = "Order Report.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          this.timer(1000).then(() => {
            this.isGenerateOrderLoading = false;
          });
        });
    },
    getDataText(header, shipment) {
      if (header.value == "id") {
        return shipment.id.toString();
      } else if (header.value == "mtlTracking") {
        return shipment.mtlTracking;
      } else if (header.value == "invoiceId") {
        return shipment.invoiceId.toString();
      } else if (header.value == "senderId") {
        return this.getCustomerName(shipment.senderId);
      } else if (header.value == "receiverId") {
        return this.getCustomerName(shipment.receiverId);
      } else if (header.value == "dateCreated") {
        return this.convertToDate(shipment.dateCreated);
      } else if (header.value == "shipmentDate") {
        return this.convertToDate(shipment.shipmentDate);
      } else if (header.value == "description") {
        return shipment.description;
      } else if (header.value == "shipmentStatusId") {
        return this.getOptionName(shipment.shipmentStatusId);
      } else if (header.value == "boxWeight") {
        return `${shipment.totalBox}/${shipment.totalWeight}`;
      } else {
        return "";
      }
    },
    getUpdateForm() {
      this.isUpdating = true;
      this.getShipmentGroupById(this.$route.params.id);
      this.getOrdersByShipmentGroupId(this.$route.params.id);
    },
    getOrdersByShipmentGroupId(shipmentGroupId) {
      backendService
        .getOrdersByShipmentGroupId(shipmentGroupId)
        .then((response) => {
          this.orders = response.data;
        });
    },
    getShipmentGroupById(id) {
      backendService.getShipmentGroupById(id).then((response) => {
        this.shipmentGroup = response.data;
        this.shipmentGroup.shipmentDate = new Date(
          this.shipmentGroup.shipmentDate
        )
          .toISOString()
          .split("T")[0];
      });
    },
    updateShipmentGroup() {
      this.isLoading = true;
      backendService.updateShipmentGroup(this.shipmentGroup).then(() => {
        this.$store.commit("updateShipmentGroup", this.shipmentGroup);
        this.timer(1000).then(() => {
          this.isLoading = false;
          eventBus.$emit(
            "setSnackbar",
            "Successfully update shipment group",
            "success",
            true
          );
        });
      });
    },
    updateShipmentStatusByGroupId() {
      this.isApplyAllLoading = true;
      let shipmentBatchInfo = {
        groupId: this.shipmentGroup.id,
        ShipmentStatusId: this.shipmentStatusId,
      };
      backendService
        .updateShipmentStatusByGroupId(shipmentBatchInfo)
        .then(() => {
          setTimeout(() => {
            this.isApplyAllLoading = false;
            this.$store.dispatch("getShipments");
            eventBus.$emit(
              "setSnackbar",
              "Successfully updated all shipments",
              "success",
              true
            );
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.isApplyAllLoading = false;
            eventBus.$emit(
              "setSnackbar",
              "Ops! something is not right",
              "error",
              true
            );
          }, 1000);
        });
    },
    refilterOrders() {
      this.orders = this.orders.filter(
        (e) => e.shipmentGroupId == this.shipmentGroup.id
      );
    },
  },
  watch: {
    "$route.name": {
      handler: function (name) {
        if (name == "NewShipmentGroup") {
          this.isLoading = false;
          this.isUpdating = false;
          this.shipmentGroup = {
            shipmentDate: null,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
