<template>
    <v-card outlined class="rounded-xl" elevation="0" min-width="75%">
        <v-card-text>
            <v-container class="d-flex justify-space-between">
                <v-container>
                    Order #
                    <span>
                        <br />
                        <h2>
                            <a
                                @click="
                                    $router.push({
                                        name: 'Shipment.OrderDetail',
                                        params: {
                                            id: order.id,
                                        },
                                    })
                                ">
                                {{ order.id }}
                            </a>
                        </h2>
                    </span>
                </v-container>

                <v-container>
                    Order Placed
                    <span>
                        <br />
                        {{ convertToDate(order.dateCreated) }}
                    </span>
                </v-container>
                <v-container>
                    Total
                    <span>
                        <br />
                        {{
                            getCurrencySymbol(order.orderDetails[0].currencyId)
                        }}
                        {{
                            calculateTotal(
                                order.subtotal,
                                order.deliveryFee
                            ).toFixed(2)
                        }}
                    </span>
                </v-container>
                <v-container>
                    Invoice #
                    <span v-if="order.invoiceId">
                        <br />
                        <a
                            @click="
                                $router.push({
                                    name: 'InvoiceUpdate',
                                    params: {
                                        id: order.invoiceId,
                                    },
                                })
                            "
                            >{{ order.invoiceId }}</a
                        >
                    </span>
                    <span v-else
                        ><br />
                        N/A
                    </span>
                </v-container>
                <v-container>
                    Customer
                    <span v-if="order.invoiceId">
                        <br />
                        {{ getCustomerName(order.customerId, false) }}
                    </span>
                    <span v-else
                        ><br />
                        N/A
                    </span>
                </v-container>
                <v-container>
                    Order Type
                    <h3
                        :class="[
                            {
                                'secondary--text': order.typeId == Stale,
                                'primary--text': order.typeId == Preorder,
                                'warning--text': order.typeId == PlaceHolder,
                                'success--text': order.typeId == Regular,
                            },
                        ]">
                        {{ getOptionName(order.typeId) }}
                    </h3>
                </v-container>
            </v-container>
            <v-divider></v-divider>
            <v-container
                v-for="orderDetail in order.orderDetails"
                :key="`order-detail-${order.id}-${orderDetail.id}`"
                class="d-flex">
                <img
                    class="image-preview"
                    :src="getProductPictureUrlById(orderDetail.pictureId)" />
                <v-container class="d-flex flex-column">
                    <a
                        @click="
                            $router.push({
                                name: 'ProductDetail',
                                params: {
                                    id: orderDetail.productId,
                                },
                            })
                        ">
                        {{ orderDetail.productTitle }}
                    </a>
                    <div class="red--text">
                        {{ getCurrencySymbol(orderDetail.currencyId) }}
                        {{ convertToCleanNumber(orderDetail.unitPrice) }}
                    </div>
                    <div>
                        <span class="mr-1">{{
                            getOptionName(orderDetail.warehouseId)
                        }}</span
                        ><span>Qty: {{ orderDetail.quantity }}</span>
                    </div>
                    <div v-if="orderDetail.caseQuantity">
                        <span class="mr-1">Case</span
                        ><span>Qty: {{ orderDetail.caseQuantity }}</span>
                    </div>
                </v-container>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import Decimal from "decimal.js";
import backendService from "@/services/backend-service";
import { OrderType } from "@/js/enum";
export default {
    name: "OrderCard",
    mixins: [generalMixin],
    props: {
        order: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    computed: {
        Regular() {
            return this.getIdByEnum(OrderType.Regular);
        },
        PlaceHolder() {
            return this.getIdByEnum(OrderType.PlaceHolder);
        },
        Stale() {
            return this.getIdByEnum(OrderType.Stale);
        },
        Preorder(){
            return this.getIdByEnum(OrderType.Preorder);
        }
    },
    methods: {
        calculateTotal(subtotal, deliveryFee) {
            return new Decimal(subtotal).plus(new Decimal(deliveryFee));
        },
        getProductPictureUrlById(imageId) {
            return backendService.getProductPictureUrlById(imageId);
        },
    },
};
</script>
<style scoped>
.image-preview {
    object-fit: contain;
    image-rendering: smooth;
    width: 100px;
    height: 100px;
}
</style>
