<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="filteredGroups"
            :items-per-page="15"
            class="elevation-1">
            <template v-slot:header>
                <tr>
                    <th v-for="(header, idx) in headers" :key="`header-${idx}`">
                        <v-text-field
                            dense
                            class="ma-2"
                            rounded
                            v-model="search[header.value]"
                            placeholder="filter"
                            solo-inverted
                            hide-details
                            flat></v-text-field>
                    </th>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <a
                            @click="
                                $router.push({
                                    name: 'ShipmentGroupUpdate',
                                    params: { id: item.id },
                                })
                            "
                            >{{ item.id }}</a
                        >
                    </td>
                    <td>{{ getOptionName(item.typeId) }}</td>
                    <td>{{ convertToDate(item.shipmentDate) }}</td>

                    <td>{{ item.totalBox }}</td>
                    <td>{{ item.totalWeight }}</td>
                    <td>{{ item.totalValue }}</td>
                    <!-- <td>{{ item.totalOrder }}</td> -->
                    <td>{{ getOptionName(item.statusId) }}</td>
                </tr>
            </template></v-data-table
        >
    </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
    name: "ShipmentGroupList",
    mixins: [generalMixin],
    data() {
        return {
            dialog: false,
            shipmentDate: null,

            headers: [
                {
                    text: "Id",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                { text: "Type", value: "typeId" },
                { text: "Shipment Date", value: "shipmentDate" },
                { text: "Total Boxes", value: "totalBox" },
                { text: "Total Weight", value: "totalWeight" },
                { text: "Total Value", value: "totalValue" },
                //{ text: "Total Order", value: "totalOrder" },
                { text: "Status", value: "statusId" },
            ],
            search: {},
        };
    },
    mounted: function () {
        this.$store.dispatch("getShipmentGroups");
    },
    computed: {
        filteredGroups() {
            let shipmentGroups = this.shipmentGroups;
            for (let key in this.search) {
                let searchStr = this.search[key].trim().toLowerCase();
                let header = this.headers.find((header) => header.value == key);
                if (searchStr && searchStr.length) {
                    shipmentGroups = shipmentGroups.filter((e) => {
                        let str = this.getDataText(header, e);
                        return str
                            .toString()
                            .trim()
                            .toLowerCase()
                            .includes(this.search[key].toLowerCase());
                    });
                }
            }
            return shipmentGroups;
        },
        shipmentGroups() {
            return this.$store.getters["shipmentGroups"];
        },
    },
    methods: {
        addShipmentGroup() {
            let shipmentGroupInfo = {};
            shipmentGroupInfo.shipmentDate = this.shipmentDate;
            backendService
                .addShipmentGroup(shipmentGroupInfo)
                .then((response) => {
                    this.dialog = false;
                    shipmentGroupInfo.id = response.data;
                    shipmentGroupInfo.totalWeight = 0;
                    shipmentGroupInfo.totalBox = 0;
                    shipmentGroupInfo.totalValue = 0;
                    this.shipmentGroups.push(shipmentGroupInfo);
                });
        },
        getDataText(header, group) {
            if (header.value == "id") {
                return group.id.toString();
            } else if (header.value == "shipmentDate") {
                return this.convertToDate(group.shipmentDate);
            } else if (header.value == "totalBox") {
                return group.totalBox.toString();
            } else if (header.value == "totalWeight") {
                return group.totalWeight.toString();
            } else if (header.value == "totalValue") {
                return group.totalValue.toString();
            } else if (header.value == "statusId") {
                return this.getOptionName(group.statusId);
            } else {
                return "";
            }
        },
    },
};
</script>
