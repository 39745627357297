<template>
    <v-card outlined class="rounded-xl" elevation="0" min-width="25%">
        <v-container fluid class="d-flex flex-column">
        
            <v-btn
                class="mb-2"
                color="warning"
                rounded
                depressed
                @click="generateInvoice(order)"
                :disabled="
                    (!!order.invoiceId && order.invoiceId != 0) ||
                    order.typeId != Regular
                "
                :loading="order.id == selectedId"
                ><i class="fas fa-plus mr-2"></i>Generate Invoice</v-btn
            >
            <v-btn
                class="mb-2"
                color="warning"
                rounded
                depressed
                @click="updateInvoice(order)"
                :disabled="
                    (!order.invoiceId || order.invoiceId == 0)"
                :loading="order.id == toUpdateInvoiceId"
                ><i class="fas fa-plus mr-2"></i>Update Invoice</v-btn
            >
            <v-btn
                class="mb-2"
                color="warning"
                rounded
                depressed
                @click="updateOrder(order)"
                :loading="order.id == toUpdateId"
                ><i class="fas fa-plus mr-2"></i>Update Order</v-btn
            >
            <v-btn
                class="mb-2"
                color="error"
                rounded
                depressed
                @click="deleteOrder(order)"
                :loading="order.id == toDeleteId"
                :disabled="
                    !!order.invoiceId ||
                    order.typeId == Stale ||
                    order.typeId == PlaceHolder
                "
                ><i class="fa-solid fa-trash-can mr-2"></i>Delete Order</v-btn
            >

            <v-btn
                class="mb-2"
                v-if="order.typeId == PlaceHolder"
                color="primary"
                rounded
                depressed
                @click="unholdOrder(order, orderToWarehouse[order.id])"
                :loading="order.id == toUnholdId"
                :disabled="
                    order.typeId != PlaceHolder || !orderToWarehouse[order.id]
                "
                ><i class="fa-solid fa-trash-can mr-2"></i>Unhold Order</v-btn
            >
            <v-autocomplete
                v-if="order.typeId == PlaceHolder"
                dense
                rounded
                outlined
                :items="warehouses"
                item-text="displayName"
                item-value="id"
                label="Back to Warehouse"
                v-model="orderToWarehouse[order.id]"></v-autocomplete>
            <v-btn
                class="mb-2"
                color="primary"
                rounded
                depressed
                @click="AddOrderToShipmentGroup(order)"
                :loading="order.id == toAddId"
                :disabled="order.typeId == Stale"
                ><i class="fa-solid fa-plus mr-2"></i>Add to Shipment
                Group</v-btn
            >
            <v-autocomplete
                v-if="order.typeId != Stale"
                dense
                rounded
                outlined
                :items="shipmentGroups"
                :item-text="formatShipmentGroup"
                item-value="id"
                label="Shipment Group"
                v-model="order.shipmentGroupId"></v-autocomplete>
        </v-container>
    </v-card>
</template>
<script>
import backendService from "@/services/backend-service";
import { InvoiceType, OrderType } from "@/js/enum";
import generalMixin from "@/js/general-mixin";
import Decimal from 'decimal.js'
export default {
    name: "OrderOptions",
    mixins: [generalMixin],
    props: {
        value: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            toDeleteId: -1,
            toUnholdId: -1,
            toAddId: -1,
            toUpdateId:-1,
            toUpdateInvoiceId:-1,
            isGenerateLoading: false,
            selectedId: -1,
            orderToWarehouse: {},
            orderToShipmentGroup: {},
        };
    },
    computed: {
        Regular() {
            return this.getIdByEnum(OrderType.Regular);
        },
        PlaceHolder() {
            return this.getIdByEnum(OrderType.PlaceHolder);
        },
        Stale() {
            return this.getIdByEnum(OrderType.Stale);
        },
        Preorder() {
            return this.getIdByEnum(OrderType.Preorder);
        },
        order: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        warehouses() {
            if (this.$store.getters["dropdownToOptions"]["warehouse"]) {
                return this.$store.getters["dropdownToOptions"]["warehouse"]
                    .options;
            }
            return [];
        },
        shipmentGroups() {
            return this.$store.getters["shipmentGroups"];
        },
    },
    methods: {
        AddOrderToShipmentGroup(order) {
            let shipmentGroupOrderInfo = {};
            this.toAddId = order.id;
            shipmentGroupOrderInfo.orderId = order.id;
            shipmentGroupOrderInfo.shipmentGroupId = order.shipmentGroupId;
            backendService
                .addOrderToShipmentGroup(shipmentGroupOrderInfo)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.toAddId = -1;
                        this.$emit("handle-event");
                        this.successSnackbar(
                            "Successfully add order to shipment group"
                        );
                    });
                })
                .catch(() => {
                    this.timer(1000).then(() => {
                        this.toAddId = -1;
                        this.errorSnackbar("Ops! Something is not right");
                    });
                });
        },
        deleteOrder(order) {
            this.toDeleteId = order.id;
            backendService
                .deleteOrderById(order.id)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.$emit('delete-event',order.id);
                        this.toDeleteId = -1;
                        this.successSnackbar("Successfully deleted order");
                    });
                })
                .catch(() => {
                    this.timer(1000).then(() => {
                        this.toDeleteId = -1;
                        this.errorSnackbar("Ops! something is not right");
                    });
                });
        },
        formatShipmentGroup(shipmentGroup) {
            if (shipmentGroup) {
                return `${shipmentGroup.id} - ${this.convertToDate(
                    shipmentGroup.shipmentDate
                )} - ${this.getOptionName(shipmentGroup.statusId)}`;
            }
        },
        generateInvoice(order) {
            this.selectedId = order.id;
            this.timer(1000).then(() => {
                this.selectedId = order.id;
                this.$router.push({
                    name: "NewInvoice",
                    params: {
                        order: order,
                        isGenerating: true,
                        type: this.getIdByEnum(InvoiceType.Order),
                    },
                });
            });
        },
        updateInvoice(order) {
            let invoice = {};
            let invoiceDetails = [];
            let totalAmount = new Decimal(0)
          
            this.toUpdateInvoiceId = order.id
            invoice.invoiceDetails = invoiceDetails;
            invoice.id = order.invoiceId;
            invoice.customerId = order.customerId
            invoice.typeId = this.getIdByEnum(InvoiceType.Order);
            invoice.associatedId = order.id;
            invoice.description = "";
            invoice.currencyId = order.orderDetails[0].currencyId
            let orderDetails = order.orderDetails
            for(let i=0;i<orderDetails.length;i++) {
                let orderDetail = orderDetails[i]
                let invoiceDetail = {
                    name: orderDetail.productTitle,
                    quantity: orderDetail.quantity,
                    unitPrice: orderDetail.unitPrice,
                    caseQuantity:orderDetail.caseQuantity,
                    isActive: true,
                };
                invoiceDetails.push(invoiceDetail);
                totalAmount = totalAmount.plus(Decimal.mul(orderDetail.quantity, orderDetail.unitPrice));
            }
            invoice.totalAmount = totalAmount;
            backendService
                .updateInvoice(invoice)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.toUpdateInvoiceId = -1;
                        this.successSnackbar(
                            "Successfully updated the invoice"
                        );
                    });
                })
                .catch(() => {
                    this.toUpdateInvoiceId = -1;
                    this.errorSnackbar("Ops! Something is not right");
                });
        },
        updateOrder(order){
            this.toUpdateId = order.id;
            this.timer(1000).then(() => {
                this.toUpdateId = -1;
                let cart = this.convertOrdertoCart(order)
                let cartCount = 0
                let keys = Object.keys(cart);
                for(let i=0;i<keys.length;i++) {
                    cartCount+= cart[keys[i]].count;
                }
                this.$store.commit('setToUpdateOrder',order);
                this.$store.commit('setIsUpdatingCart',true)
                this.$store.commit('setCart',cart)
                this.$store.commit('setCartCount',cartCount);
                localStorage.setItem('toUpdateOrder',JSON.stringify(order));
                localStorage.setItem('isUpdatingCart',true)
                localStorage.setItem('cart',JSON.stringify(cart))
                localStorage.setItem('cartCount',cartCount)
                this.successSnackbar("Updating Cart");
            })
        },
        unholdOrder(order, warehouseId) {
            this.toUnholdId = order.id;
            backendService
                .unholdOrder(order.id, warehouseId)
                .then(() => {
                    this.timer(1000).then(() => {
                        this.toUnholdId = -1;
                        order.typeId = this.getIdByEnum(OrderType.Stale);
                        this.successSnackbar("Successfully unholded order");
                    });
                })
                .catch(() => {
                    this.timer(1000).then(() => {
                        this.toUnholdId = -1;
                        this.errorSnackbar("Ops! something is not right");
                    });
                });
        },
    },
};
</script>
