<template>
    <v-card class="grey lighten-3" elevation="0" width="20%">
        <v-card-text>
            <h1>Filter</h1>
        </v-card-text>
        <v-card-text>
            <v-container class="white rounded-xl">
                <v-container class="d-flex">
                    <v-text-field
                        rounded
                        outlined
                        dense
                        label="Title"
                        v-model="filter.searchText"></v-text-field>
                </v-container>
                <v-container class="d-flex">
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="mr-2"
                        label="Min Price"
                        v-model.number="filter.minPrice"
                        @change="
                            filter.minPrice =
                                filter.minPrice != '' ? filter.minPrice : null
                        "
                        prefix="$"></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="ml-2"
                        label="Max Price"
                        v-model.number="filter.maxPrice"
                        @change="
                            filter.maxPrice =
                                filter.maxPrice != '' ? filter.maxPrice : null
                        "
                        prefix="$"></v-text-field>
                </v-container>
                <v-container class="d-flex">
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="mr-2"
                        label="Min US Quantity"
                        @change="
                            filter.minUsQuantity =
                                filter.minUsQuantity != ''
                                    ? filter.minUsQuantity
                                    : null
                        "
                        v-model.number="filter.minUsQuantity"></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="ml-2"
                        label="Max US Quantity"
                        @change="
                            filter.maxUsQuantity =
                                filter.maxUsQuantity != ''
                                    ? filter.maxUsQuantity
                                    : null
                        "
                        v-model.number="filter.maxUsQuantity"></v-text-field>
                </v-container>
                <v-container class="d-flex">
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="mr-2"
                        label="Min VN Quantity"
                        @change="
                            filter.minVnQuantity =
                                filter.minVnQuantity != ''
                                    ? filter.minVnQuantity
                                    : null
                        "
                        v-model.number="filter.minVnQuantity"></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="ml-2"
                        label="Max VN Quantity"
                        @change="
                            filter.maxVnQuantity =
                                filter.maxVnQuantity != ''
                                    ? filter.maxVnQuantity
                                    : null
                        "
                        v-model.number="filter.maxVnQuantity"></v-text-field>
                </v-container>
                <v-container class="d-flex">
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="mr-2"
                        label="Min Quantity"
                        @change="
                            filter.minQuantity =
                                filter.minVnQuantity != ''
                                    ? filter.minQuantity
                                    : null
                        "
                        v-model.number="filter.minQuantity"></v-text-field>
                    <v-text-field
                        rounded
                        outlined
                        dense
                        class="ml-2"
                        label="Max Quantity"
                        @change="
                            filter.maxQuantity =
                                filter.maxVnQuantity != ''
                                    ? filter.maxQuantity
                                    : null
                        "
                        v-model.number="filter.maxQuantity"></v-text-field>
                </v-container>
                <v-container>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        label="Brand"
                        :items="brands"
                        item-text="displayName"
                        item-value="id"
                        v-model="filter.brandId"></v-autocomplete>
                </v-container>

                <v-container>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        label="Category"
                        :items="categories"
                        item-text="displayName"
                        item-value="id"
                        v-model="filter.lvl1Id"></v-autocomplete>
                </v-container>
                <v-container>
                    <v-autocomplete
                        rounded
                        outlined
                        dense
                        label="Type"
                        :items="productTypes"
                        item-text="displayName"
                        item-value="id"
                        v-model="filter.lvl2Id"></v-autocomplete>
                </v-container>

                <v-container fluid class="d-flex justify-space-between">
                    <v-btn
                        color="primary"
                        depressed
                        icon
                        @click="generateProductReport"
                        :loading="isExportLoading">
                        <i class="fa-solid fa-download"></i>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        @click="emitEvent"
                        :loading="isLoading">
                        <i class="fas fa-sync-alt mr-2"></i>
                        Apply
                    </v-btn>
                </v-container>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
    name: "FilterPanel",
    mixins: [generalMixin],
    mounted: function () {
        let query = this.$route.query;
        let filter = {};
        if (query.searchText) filter.searchText = query.searchText;
        if (query.minPrice) filter.minPrice = Number(query.minPrice);
        if (query.maxPrice) filter.maxPrice = Number(query.maxPrice);
        if (query.minQuantity) filter.minQuantity = Number(query.minQuantity);
        if (query.maxQuantity) filter.maxQuantity = Number(query.maxQuantity);
        if (query.minVnQuantity) filter.minVnQuantity = Number(query.minVnQuantity);
        if (query.maxVnQuantity) filter.maxVnQuantity = Number(query.maxVnQuantity);
        if (query.minUsQuantity) filter.minUsQuantity = Number(query.minUsQuantity);
        if (query.maxUsQuantity) filter.maxUsQuantity = Number(query.maxUsQuantity);
        if (query.brandId) filter.brandId = Number(query.brandId);
        if (query.lvl1Id) filter.lvl1Id = Number(query.lvl1Id);
        if (query.lvl2Id) filter.lvl2Id = Number(query.lvl2Id);
        this.filter = filter;
    },
    data() {
        return { isExportLoading: false, isLoading: false, filter: {} };
    },
    computed: {
        brands() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["brand"]
            ) {
                return this.$store.getters["dropdownToOptions"]["brand"]
                    .options;
            }
            return [];
        },
        categories() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["category"]
            ) {
                return this.$store.getters["dropdownToOptions"]["category"]
                    .options;
            }
            return [];
        },
        productTypes() {
            if (
                this.$store.getters["dropdownToOptions"] &&
                this.$store.getters["dropdownToOptions"]["productType"]
            ) {
                return this.$store.getters["dropdownToOptions"]["productType"]
                    .options;
            }
            return [];
        },
    },
    methods: {
        emitEvent() {
            this.isLoading = true;
            let newQuery = { ...this.filter };
            this.$router.replace({ query: newQuery }).catch(() => {});
            this.timer(1000).then(() => {
                this.isLoading = false;
            });
        },
        generateProductReport() {
            this.isExportLoading = true;
            backendService
                .generateProductReport(this.filter)
                .then((response) => {
                    this.timer(1000).then(() => {
                        this.isExportLoading = false;
                    });
                    var binary = atob(response.data);

                    var array = new Uint8Array(binary.length);
                    for (var i = 0; i < binary.length; i++) {
                        array[i] = binary.charCodeAt(i);
                    }

                    const url = window.URL.createObjectURL(new Blob([array]));

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "Products.xlsx";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
        },
    },
};
</script>
