<template>
	<div>
		<!-- <shipment-management-navbar></shipment-management-navbar> -->
		<router-view v-if="!isLoading" />
		<v-container v-else
			><v-card elevation="0">
				<v-card-text class="d-flex justify-center">
					<v-progress-circular
						indeterminate
						color="primary"></v-progress-circular>
				</v-card-text> </v-card
		></v-container>
	</div>
</template>
<script>
///import ShipmentManagementNavbar from "@/components/ShipmentManagementNavbar.vue";
export default {
	name: "ShipmentManagement",
	//components: { "shipment-management-navbar": ShipmentManagementNavbar },
	mounted: function () {
		setTimeout(() => {
			this.isLoading = false;
		}, 1500);
		this.$store.dispatch("getCustomers");
		this.$store.dispatch("getShipments");
		this.$store.dispatch("getShipmentGroups");
		this.$store.dispatch("getOrders");
	},
	data() {
		return { tab: 0, isLoading: true };
	},
};
</script>
