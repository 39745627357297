<template>
  <v-container fluid class="pa-0">
    <v-card class="grey lighten-3">
      <v-card-text>
        <h1>Your Orders</h1>
      </v-card-text>

      <v-card-text
        v-if="orders.length < 1"
        class="grey lighten-2 rounded-xl text-center d-flex flex-column"
        ><h2>Nothing to See</h2>
        <i class="fas fa-spider"></i
      ></v-card-text>
      <v-card-text class="mb-2" elevation="0">
        <v-container>
          <v-text-field
            rounded
            outlined
            dense
            label="Search"
            v-model="orderSearch"
            clearable
          ></v-text-field>
        </v-container>
        <v-container class="white rounded-xl">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab>Regular ({{ regularOrders.length }})</v-tab>
            <v-tab>Preorder ({{ preorders.length }})</v-tab>
            <v-tab>On Hold ({{ otherOrders.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl mr-2 flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in regularOrders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="regularOrders[idx]"
                  @delete-event="refilterOrders"
                ></order-options>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl mr-2 flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in preorders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="preorders[idx]"
                  @delete-event="refilterOrders"
                ></order-options>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fluid
                class="d-flex white rounded-xl flex-sm-wrap justify-sm-end"
                v-for="(order, idx) in otherOrders"
                :key="`order-${order.id}`"
              >
                <order-card :order="order"></order-card>
                <order-options
                  v-model="otherOrders[idx]"
                  @delete-event="refilterOrders"
                ></order-options> </v-container
            ></v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
import OrderCard from "@/components/OrderCard.vue";
import OrderOptions from "@/components/OrderOptions.vue";
import { OrderType } from "@/js/enum";
export default {
  name: "OrderList",
  mixins: [generalMixin],
  components: { "order-card": OrderCard, "order-options": OrderOptions },
  data() {
    return {
      orders: [],
      tab: null,
    };
  },
  created: function () {
    this.getOrders();
  },
  computed: {
    Regular() {
      return this.getIdByEnum(OrderType.Regular);
    },
    PlaceHolder() {
      return this.getIdByEnum(OrderType.PlaceHolder);
    },
    Stale() {
      return this.getIdByEnum(OrderType.Stale);
    },
    Preorder() {
      return this.getIdByEnum(OrderType.Preorder);
    },
    filteredOrders() {
      let orders = this.orders;
      let searchStr = this.orderSearch;
      searchStr = searchStr ? searchStr.trim().toLowerCase() : "";
      if (searchStr && searchStr.length) {
        orders = orders.filter((e) => {
          let orderDetails = e.orderDetails;
          for (let i = 0; i < orderDetails.length; i++) {
            let str = orderDetails[i].productTitle;
            return str.toString().trim().toLowerCase().includes(searchStr);
          }
        });
      }

      return orders;
    },
    regularOrders() {
      return this.filteredOrders.filter((e) => e.typeId == this.Regular);
    },
    preorders() {
      return this.filteredOrders.filter((e) => e.typeId == this.Preorder);
    },
    otherOrders() {
      return this.filteredOrders.filter(
        (e) => e.typeId != this.Regular && e.typeId != this.Preorder
      );
    },
    orderSearch: {
      get() {
        return this.$store.getters["orderSearch"];
      },
      set(val) {
        return this.$store.commit("setOrderSearch", val);
      },
    },
  },
  methods: {
    getDataText(order) {
      return `${order.id}`;
    },

    getOrders() {
      backendService.getOrders().then((response) => {
        this.orders = response.data;
      });
    },
    refilterOrders(id) {
      this.orders = this.orders.filter((x) => x.id != id);
    },
  },
};
</script>
<style scoped></style>
