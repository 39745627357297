var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":"","elevation":"0","min-width":"75%"}},[_c('v-card-text',[_c('v-container',{staticClass:"d-flex justify-space-between"},[_c('v-container',[_vm._v(" Order # "),_c('span',[_c('br'),_c('h2',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                                    name: 'Shipment.OrderDetail',
                                    params: {
                                        id: _vm.order.id,
                                    },
                                })}}},[_vm._v(" "+_vm._s(_vm.order.id)+" ")])])])]),_c('v-container',[_vm._v(" Order Placed "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDate(_vm.order.dateCreated))+" ")])]),_c('v-container',[_vm._v(" Total "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.getCurrencySymbol(_vm.order.orderDetails[0].currencyId))+" "+_vm._s(_vm.calculateTotal( _vm.order.subtotal, _vm.order.deliveryFee ).toFixed(2))+" ")])]),_c('v-container',[_vm._v(" Invoice # "),(_vm.order.invoiceId)?_c('span',[_c('br'),_c('a',{on:{"click":function($event){return _vm.$router.push({
                                name: 'InvoiceUpdate',
                                params: {
                                    id: _vm.order.invoiceId,
                                },
                            })}}},[_vm._v(_vm._s(_vm.order.invoiceId))])]):_c('span',[_c('br'),_vm._v(" N/A ")])]),_c('v-container',[_vm._v(" Customer "),(_vm.order.invoiceId)?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.getCustomerName(_vm.order.customerId, false))+" ")]):_c('span',[_c('br'),_vm._v(" N/A ")])]),_c('v-container',[_vm._v(" Order Type "),_c('h3',{class:[
                        {
                            'secondary--text': _vm.order.typeId == _vm.Stale,
                            'primary--text': _vm.order.typeId == _vm.Preorder,
                            'warning--text': _vm.order.typeId == _vm.PlaceHolder,
                            'success--text': _vm.order.typeId == _vm.Regular,
                        },
                    ]},[_vm._v(" "+_vm._s(_vm.getOptionName(_vm.order.typeId))+" ")])])],1),_c('v-divider'),_vm._l((_vm.order.orderDetails),function(orderDetail){return _c('v-container',{key:`order-detail-${_vm.order.id}-${orderDetail.id}`,staticClass:"d-flex"},[_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(orderDetail.pictureId)}}),_c('v-container',{staticClass:"d-flex flex-column"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                            name: 'ProductDetail',
                            params: {
                                id: orderDetail.productId,
                            },
                        })}}},[_vm._v(" "+_vm._s(orderDetail.productTitle)+" ")]),_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.getCurrencySymbol(orderDetail.currencyId))+" "+_vm._s(_vm.convertToCleanNumber(orderDetail.unitPrice))+" ")]),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getOptionName(orderDetail.warehouseId)))]),_c('span',[_vm._v("Qty: "+_vm._s(orderDetail.quantity))])]),(orderDetail.caseQuantity)?_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Case")]),_c('span',[_vm._v("Qty: "+_vm._s(orderDetail.caseQuantity))])]):_vm._e()])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }