var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-space-between pa-0",attrs:{"fluid":""}},[_c('v-expand-x-transition',[(_vm.expand)?_c('filter-panel',{on:{"handle-event":_vm.applyFilter}}):_vm._e()],1),_c('v-card',{staticClass:"grey lighten-3",attrs:{"elevation":"0","width":_vm.expand ? '80%' : '100%'}},[_c('v-card-text',[_c('v-container',[_c('v-container',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","depressed":"","rounded":""},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('i',{staticClass:"fa-solid fa-bars"})]),_c('v-text-field',{staticClass:"ma-2",attrs:{"dense":"","rounded":"","outlined":"","label":"Title Filter","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._l((_vm.filteredProducts),function(product){return _c('v-container',{key:product.id,staticClass:"d-flex justify-space-between white rounded-xl mb-2"},[_c('product-card',{attrs:{"showQty":"","product":product,"allow-add-to-cart":true}}),_c('v-container',{staticClass:"my-0 py-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"warning","depressed":"","rounded":""},on:{"click":function($event){return _vm.$router.push({
                                        name: 'ProductUpdate',
                                        params: { id: product.id },
                                    })}}},[_c('i',{staticClass:"fas fa-pencil-alt mr-2"}),_vm._v(" Update ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"warning","depressed":"","rounded":""},on:{"click":function($event){return _vm.addStock(product)}}},[_c('i',{staticClass:"fas fa-people-carry mr-2"}),_vm._v(" Add Stock ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":"","rounded":""},on:{"click":function($event){_vm.getStocksByProductId(product.id);
                                    _vm.getOrdersByProductId(product.id);}}},[_c('i',{staticClass:"fas fa-download mr-2"}),_vm._v(" Get Details ")]),_c('v-btn',{attrs:{"color":"error","depressed":"","rounded":"","loading":product.id == _vm.isDeleteLoadingIdx},on:{"click":function($event){return _vm.openDeleteDialog(product.id)}}},[_c('i',{staticClass:"fa-solid fa-x mr-2"}),_vm._v(" Remove ")])],1),_c('v-container',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"red--text"},[_c('h3',[_vm._v("Average Stock Price")])]),_c('div',[_c('h3',[_vm._v(" $"+_vm._s(_vm.findAvgFromStocks(product.id))+" ")])])]),_c('v-container',{staticClass:"rounded-xl",staticStyle:{"border":"1px solid lightgrey"}},[_c('v-simple-table',{attrs:{"height":"250px"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Id")]),_c('th',{staticClass:"text-left"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-left"},[_vm._v(" Stock Price ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Date Purchased ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Created By ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Date Created ")]),_c('th',{staticClass:"text-left"},[_vm._v("Exp Date")]),_c('th',[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.productIdToStocks[
                                            product.id
                                        ]),function(stock){return _c('tr',{key:`${product.id}-stock-${stock.id}`},[_c('td',[_vm._v(" "+_vm._s(stock.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(stock.quantity)+" ")]),_c('td',[_vm._v(" $"+_vm._s(_vm.convertToCleanNumber( stock.stockPrice ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertToDate( stock.datePurchased ))+" ")]),_c('td',[_vm._v(" "+_vm._s(stock.createdBy.first)+" "+_vm._s(stock.createdBy.last)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertToDate( stock.dateCreated ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertToDate(stock.expDate))+" ")]),_c('td',[_c('v-btn',{attrs:{"color":"warning","icon":"","depressed":"","disabled":!stock.isActive},on:{"click":function($event){return _vm.updateStock(
                                                        product,
                                                        stock
                                                    )}}},[_c('i',{staticClass:"fa-solid fa-gear"})])],1)])}),0)])],1)],1),_c('v-container',{staticClass:"w-25 rounded-xl",staticStyle:{"border":"1px solid lightgrey"},attrs:{"fluid":""}},[_c('v-simple-table',{attrs:{"height":"350px"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Order Id")]),_c('th',{staticClass:"text-left"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-left"},[_vm._v("Date Created")]),_c('th',{staticClass:"text-left"},[_vm._v("Invoice")])])]),_c('tbody',_vm._l((_vm.productIdToOrders[
                                        product.id
                                    ]),function(order){return _c('tr',{key:`${product.id}-order-${order.id}`},[_c('td',[_vm._v(" "+_vm._s(order.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(order.orderDetails[0].quantity)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertToDate(order.dateCreated))+" ")]),_c('td',[(order.invoiceId)?_c('span',[_c('a',{on:{"click":function($event){return _vm.openInvoice(
                                                        order.invoiceId
                                                    )}}},[_vm._v(_vm._s(order.invoiceId))])]):_c('span')])])}),0)])],1)],1)})],2)],1)],1),(_vm.inStockDialog)?_c('in-stock-dialog',{attrs:{"p-product":_vm.selectedProduct,"p-stock":_vm.selectedStock,"is-updating":_vm.isUpdatingStock},on:{"handle-event":function($event){return _vm.getStocksByProductId(_vm.selectedProduct.id)}},model:{value:(_vm.inStockDialog),callback:function ($$v) {_vm.inStockDialog=$$v},expression:"inStockDialog"}}):_vm._e(),_c('general-dialog',{attrs:{"dialog":_vm.deleteDialog,"options":_vm.deleteOptions},on:{"handle-event":_vm.deleteProduct},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_c('v-overlay',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }